import React from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";

import Icon from "../../custom-widgets/icon";

// Styles
import styles from "./calculator.module.scss";

/**
 * This is the component to render Other Mortgage Calculator Links.
 *
 * @param {Object} props - The object containing props.
 * @param {Object[]} props.ratesData - Rates Data from the API is used for the propTypes validations.
 * @param {string} props.loanType - Loan Type like "Home Loan", "Remodeling".
 * @param {boolean} props.isSpanish - If we are in a spanish page.
 * @returns {JSX.Element} Returns the JSX component to render
 */
const OtherLoanCalculatorsLinks = ({
  ratesData = [
    {
      LoanProgram: "30-Year Fixed Mortgage",
      InterestRate: 6.875,
      APR: 7.091,
      Fee: 1.125,
      PIPayments: 2102.17,
      LoanTerms: ["30 Year Fixed"],
      LoanType: "Default-Special"
    }
  ], // This is just an example we have all in the rates API.
  loanType = "Home Loan",
  isSpanish = false
}) => {
  const LINKS_ORDER = {
    Construction: [
      "heloc-calculator-link",
      "first-time-home-buyer-calculator-link",
      "lot-loans-calculator-link",
      "remodeling-calculator-link",
      "home-mortgage-calculator-link",
      "refinance-calculator-link"
    ],
    HELOAN: [
      "first-time-home-buyer-calculator-link",
      "construction-calculator-link",
      "lot-loans-calculator-link",
      "remodeling-calculator-link",
      "home-mortgage-calculator-link",
      "refinance-calculator-link"
    ],
    HELOC: [
      "first-time-home-buyer-calculator-link",
      "construction-calculator-link",
      "lot-loans-calculator-link",
      "remodeling-calculator-link",
      "home-mortgage-calculator-link",
      "refinance-calculator-link"
    ],
    "Home Loan": [
      "heloc-calculator-link",
      "construction-calculator-link",
      "lot-loans-calculator-link",
      "remodeling-calculator-link",
      "first-time-home-buyer-calculator-link",
      "refinance-calculator-link"
    ],
    "Lot Loan": [
      "heloc-calculator-link",
      "construction-calculator-link",
      "home-mortgage-calculator-link",
      "remodeling-calculator-link",
      "refinance-calculator-link",
      "first-time-home-buyer-calculator-link"
    ],
    Refinance: [
      "heloc-calculator-link",
      "construction-calculator-link",
      "lot-loans-calculator-link",
      "remodeling-calculator-link",
      "home-mortgage-calculator-link",
      "first-time-home-buyer-calculator-link"
    ],
    Remodeling: [
      "heloc-calculator-link",
      "construction-calculator-link",
      "lot-loans-calculator-link",
      "first-time-home-buyer-calculator-link",
      "home-mortgage-calculator-link",
      "refinance-calculator-link"
    ]
  };

  const ALL_CALCULATORS_LINKS = [
    {
      id: "construction-calculator-link",
      url: "/personal-banking/home-loans/construction-loans#mortgage-calculator-section",
      name: "construction-calculator-link",
      iconName: "pencil-ruler",
      text: "Construction"
    },
    {
      id: "first-time-home-buyer-calculator-link",
      url: "/personal-banking/home-loans/first-time-home-buyer-loans#mortgage-calculator-section",
      name: "first-time-home-buyer-calculator-link",
      iconName: "user",
      text: "First Time Home Buyer"
    },
    {
      id: "heloc-calculator-link",
      url: "/personal-banking/home-loans/heloc-home-equity-line-of-credit#mortgage-calculator-section",
      name: "first-time-home-buyer-calculator-link",
      iconName: "wallet",
      text: "HELOC"
    },
    {
      id: "home-mortgage-calculator-link",
      url: "/personal-banking/home-loans/home-mortgage#mortgage-calculator-section",
      name: "home-mortgage-calculator-link",
      iconName: "house",
      text: "Mortgage"
    },
    {
      id: "lot-loans-calculator-link",
      url: "/personal-banking/home-loans/land-loans#mortgage-calculator-section",
      name: "lot-loans-calculator-link",
      iconName: "mountain",
      text: "Lot Loans"
    },
    {
      id: "refinance-calculator-link",
      url: "/personal-banking/home-loans/mortgage-refinance#mortgage-calculator-section",
      name: "refinance-calculator-link",
      iconName: "percentage",
      text: "Refinancing"
    },
    {
      id: "remodeling-calculator-link",
      url: "/personal-banking/home-loans/home-improvement-loans#mortgage-calculator-section",
      name: "remodeling-calculator-link",
      iconName: "paint-roller",
      text: "Remodeling"
    }
  ];

  // Filter out calculators not included in LINKS_ORDER and sort the result based on the order specified in LINKS_ORDER
  const SORTED_LINKS = LINKS_ORDER[loanType]
    ? ALL_CALCULATORS_LINKS.filter((object) => LINKS_ORDER[loanType].includes(object.id)).sort((a, b) => {
        return LINKS_ORDER[loanType].indexOf(a.id) - LINKS_ORDER[loanType].indexOf(b.id);
      })
    : [];

  const LinksToRender = () =>
    SORTED_LINKS.map((linkDetails, idx) => {
      const isFourthOrMultipleOfThree = idx === 3 || (idx > 3 && (idx - 3) % 3 === 0);
      const isLastTwo = idx >= SORTED_LINKS.length - 2; // Check if the index is one of the last two positions

      const additionalClasses = `${isLastTwo ? "" : isFourthOrMultipleOfThree ? " mb-4 mb-md-0 mb-lg-4" : " mb-4"}`;

      return (
        <div key={idx} className={"col-6 col-md-4 col-lg-6" + additionalClasses}>
          <Link id={linkDetails.id} key={idx} to={linkDetails.url} className={"mx-0 " + styles.otherCalculatorLink}>
            <h5 className="mb-0 d-flex align-items-start">
              <Icon name={linkDetails.iconName} lib="fas" class="mt-05 mr-2 col-2 px-0 col-sm-1" />
              {linkDetails.text}
            </h5>
          </Link>
        </div>
      );
    });

  return (
    <div className="col-lg-6 order-4">
      <h5>{isSpanish ? "Otras calculadoras de préstamos" : "Other Loan Calculators"}</h5>
      <div className="row">
        <LinksToRender />
      </div>
    </div>
  );
};

export default OtherLoanCalculatorsLinks;

OtherLoanCalculatorsLinks.propTypes = {
  ratesData: PropTypes.arrayOf(PropTypes.object).isRequired,
  loanType: (props, propName, componentName) => {
    // Valid values example ["Home Loan","Refinance","Construction","Lot Loan","HELOC","Smart Start","HELOAN","Remodeling"]
    if (props.ratesData.length) {
      const validValues = props.ratesData.map((rate) => rate.LoanType);

      if (!validValues.includes(props[propName])) {
        return new Error(
          `Invalid prop value ${propName}="${props[propName]}" supplied to ${componentName}.` +
            `\n\nExpected values: ${JSON.stringify(validValues)}.\n\nValidation failed.`
        );
      }
    }
  },
  isSpanish: PropTypes.bool
};
