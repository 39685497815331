import { useState } from "react";

const useFormattedNumber = () => {
  const [formattedValues, setFormattedValues] = useState({});
  const [numericValues, setNumericValues] = useState({});

  const formatCurrency = (value) => {
    // Remove non-numeric characters except the decimal point
    let cleanedValue = (value + "").replace(/[^\d.]/g, "");

    // Split the cleaned string into parts based on the decimal point
    let parts = cleanedValue.split(".");

    // Add thousands separators to the integer part
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    // Rejoin the parts with a decimal point
    let formattedValue = parts.join(".");

    return formattedValue;
  };

  const formatValue = (inputName, value, maxDigits = 9, maxDecimals = 2) => {
    // Remove non-numeric characters except the decimal point
    let cleanedValue = value === null ? "" : (value + "").replace(/[^\d.]/g, "");

    // Handle the case where the input is empty
    if (cleanedValue === "") {
      setNumericValues((prevNumericValues) => ({
        ...prevNumericValues,
        [inputName]: null
      }));
      setFormattedValues((prevFormattedValues) => ({
        ...prevFormattedValues,
        [inputName]: ""
      }));
      return null;
    }

    // Split the cleaned string into parts based on the dot
    let parts = cleanedValue.split(".");

    // Remove leading zeros from the part before the decimal point
    parts[0] = parts[0].replace(/^0+/, "");

    // Ensure there's at least one zero before the decimal point
    if (parts[0] === "") {
      parts[0] = "0";
    }

    // Prevent entering multiple decimal points
    if (parts.length > 1) {
      // If there's already a decimal point, remove the second one
      parts = [parts[0], parts.slice(1).join("")];
    }

    // Limit the digits part to the specified maximum digits
    if (parts[0].length > maxDigits) {
      parts[0] = parts[0].substring(0, maxDigits);
    }

    // Limit the decimal part to the specified maximum decimals
    if (parts.length > 1) {
      parts[1] = parts[1].substring(0, maxDecimals);
    }

    // Join the parts back together with a dot
    const formattedValue = parts.join(".");

    // Parse the numeric value and store it in state
    const numericValue = formattedValue.includes(".") ? parseFloat(formattedValue) : parseInt(formattedValue);
    setNumericValues((prevNumericValues) => ({
      ...prevNumericValues,
      [inputName]: isNaN(numericValue) ? null : numericValue
    }));

    // Format the value as currency and store it in state
    const currencyValue = formatCurrency(formattedValue);
    setFormattedValues((prevFormattedValues) => ({
      ...prevFormattedValues,
      [inputName]: currencyValue
    }));

    return numericValue;
  };

  return { formattedValues, numericValues, formatValue };
};

export default useFormattedNumber;
