import React, { useState } from "react";
import PropTypes from "prop-types";

import Button from "../../custom-widgets/button";
import LoanOfficerSearch from "../../loan-officers/loan-officer-search";
import Icon from "../../custom-widgets/icon";

//Styles
import styles from "./calculator.module.scss";

/**
 * This is the component to render APR info and its bottom buttons.
 *
 * @param {Object} props - The object containing props.
 * @param {Object[]} props.ratesData - Rates Data from the API.
 * @param {string} props.calculatorTitle - Assigned calculator title.
 * @param {string} props.loanType - Loan Type like "Home Loan", "Remodeling".
 * @param {boolean} props.isSpanish - If we are in a spanish page.
 * @returns {JSX.Element} Returns the JSX component to render
 */
const APRRateInfo = ({ ratesData, calculatorTitle, loanType, isSpanish }) => {
  const [showLOSearchModal, setShowLOSearchModal] = useState(false);
  const handleCloseLOSearchModal = () => setShowLOSearchModal(false);
  const handleShowLOSearchModal = () => {
    setShowLOSearchModal(true);
  };

  const IS_CALCULATOR_CONSTRUCTION_STATE = calculatorTitle.includes(" Construction Loan")
    ? calculatorTitle.split(" Construction Loan").filter((text) => text !== "").length
      ? true
      : false
    : false;

  const getStateName = (stateNameSent) => {
    const anForStateName = ["Arizona", "Oregon", "Idaho"];
    let title =
      (isSpanish ? "en " : anForStateName.find((state) => state === stateNameSent) ? "an " : "a ") + stateNameSent;

    return title;
  };

  const STATE_NAME_BUTTON_TEXT = IS_CALCULATOR_CONSTRUCTION_STATE
    ? getStateName(calculatorTitle.split(" Construction Loan")[0])
    : isSpanish
    ? "en"
    : "a";

  const getAPR = () => {
    /* NOTE: the DEFAULT_APR should be the highest APR value from the most recent Mortgage Rates spreadsheet. 
      TODO: create a default (fallback) loan type in the Rates API with default values we can use on all the calculators. */
    const DEFAULT_APR = "10.041";
    if (ratesData.length) {
      const loanData = ratesData.find((item) => {
        return item.LoanType === loanType;
      });
      // console.info("returning APR for " + loanType + " from " + loanData.LoanProgram + " = " + loanData.APR);
      return loanData?.APR || DEFAULT_APR;
    }
    return DEFAULT_APR;
  };

  const BOTTOM_BUTTONS_PROPS = {
    left: {
      id: "today-rates-link",
      className: "btn-link w-100",
      type: "link",
      url: isSpanish
        ? "/es/banca-personal/tasas-de-interes-hipotecarias-actuales"
        : "/personal-banking/home-loans/mortgage-rates",
      text: isSpanish ? "Vea las tasas de hoy" : "See Today's Rates",
      showIcon: false
    },
    right: {
      id:
        loanType === "HELOC" || loanType === "HELOAN"
          ? "calc-home-equity-apply-now-modal-cta"
          : "calc-mortgage-apply-now-modal-cta",
      className: "btn-primary w-100",
      type: "button",
      onClick: handleShowLOSearchModal,
      text: isSpanish ? "Aplicar ahora" : "Apply Now",
      showIcon: false
    }
  };

  const ButtonWithProps = ({ buttonSide }) => {
    const { className, ...remainingProps } = BOTTOM_BUTTONS_PROPS[buttonSide];
    return <Button class={className} {...remainingProps} />;
  };

  return (
    <div className="col-md-6 order-3 order-lg-2">
      <p className="mb-0">{isSpanish ? "Tasas tan bajas como" : "Rates as low as"}</p>

      <div id="mortgage-calculator-apr" className="row mx-0 my-2 my-lg-0">
        <div id="apr-rate" className={`mt-0 mb-3 text-green-60 g ${styles.aprRateStyle}`}>
          {getAPR()}
        </div>
        <div className="ml-1">
          <div id="apr-percent" className={`my-2 text-green-60 ${styles.aprPercentStyle}`}>
            %
          </div>
          <div id="apr-label" className={`mb-0 text-green-60 ${styles.aprLabelStyle}`}>
            APR
          </div>
        </div>
      </div>
      <div className="row mb-2 mb-md-0">
        <LoanOfficerSearch
          isModalSearch={true}
          show={showLOSearchModal}
          handleClose={handleCloseLOSearchModal}
          loanType={loanType}
        />
        {loanType === "HELOC" ? (
          <div className="col-12 mb-4">
            <ButtonWithProps buttonSide="right" />
          </div>
        ) : (
          <>
            <div className={IS_CALCULATOR_CONSTRUCTION_STATE ? "col-12 col-xl-5 mb-4" : "col-12 col-lg-6 mb-4"}>
              <ButtonWithProps buttonSide="left" />
            </div>
            <div className={IS_CALCULATOR_CONSTRUCTION_STATE ? "col-12 col-xl-7 mb-4" : "col-12 col-lg-6 mb-4"}>
              <ButtonWithProps buttonSide="right" />
            </div>
          </>
        )}
      </div>
      {loanType === "HELOC" && (
        <>
          <div className="mb-3">
            <a
              target="_blank"
              className="text-decoration-none"
              href="/documents/wafd-bank-terms-of-home-equity-line-of-credit.pdf"
              id={`calculator-heloc-disclosure-pdf-link`}
            >
              <Icon name="file-pdf" class="mr-2" />
              HELOC Disclosure
            </a>
          </div>
          <div className="mb-3">
            <a
              className="text-decoration-none"
              target="_blank"
              href="/documents/wafd-bank-what-you-should-know-about-heloc.pdf"
              id={`calculator-what-you-should-know-about-heloc-pdf-link`}
            >
              <Icon name="file-pdf" class="mr-2" />
              What you should know about home equity lines of credit
            </a>
          </div>
        </>
      )}
    </div>
  );
};

export default APRRateInfo;

APRRateInfo.defaultProps = {
  ratesData: [
    {
      LoanProgram: "30-Year Fixed Mortgage",
      InterestRate: 6.875,
      APR: 7.091,
      Fee: 1.125,
      PIPayments: 2102.17,
      LoanTerms: ["30 Year Fixed"],
      LoanType: "Default-Special"
    }
  ], // This is just an example we have all in the rates API.
  calculatorTitle: "",
  loanType: "Home Loan",
  isSpanish: false
};

APRRateInfo.propTypes = {
  ratesData: PropTypes.arrayOf(PropTypes.object).isRequired,
  calculatorTitle: PropTypes.string.isRequired,
  loanType: (props, propName, componentName) => {
    // Valid values example ["Home Loan","Refinance","Construction","Lot Loan","HELOC","Smart Start","HELOAN","Remodeling"]
    if (props.ratesData.length) {
      const validValues = props.ratesData.map((rate) => rate.LoanType);

      if (!validValues.includes(props[propName])) {
        return new Error(
          `Invalid prop value ${propName}="${props[propName]}" supplied to ${componentName}.` +
            `\n\nExpected values: ${JSON.stringify(validValues)}.\n\nValidation failed.`
        );
      }
    }
  },
  isSpanish: PropTypes.bool
};
