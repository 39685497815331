import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

//Styles
import styles from "./calculator.module.scss";
import DecimalInput from "../../inputs/decimal-input";
import MemoizedSelectInput from "../../inputs/memoized-select-input";

/**
 * This is the component to render the form to generate the estimated monthly payment.
 *
 * @param {Object} props - The object containing props.
 * @param {Object[]} props.ratesData - Rates Data from the API.
 * @param {string} props.loanType - Loan Type like "Home Loan", "Remodeling".
 * @param {boolean} props.isSpanish - If we are in a spanish page.
 * @param {Object} props.FORM_INITIAL_VALUES - The initial values for the form, with each input id as key and its initial value as value.
 * @param {Object} props.FORM_SET_STATES - State update function for each input in the form ordered by id.
 * @param {Object} props.formLabels - Object with the labels that we can use for each input/select field, with each input id as key.
 * @param {function} props.decimalInputRefs - React ref with the array of refs for the input forms to update its values.
 * @param {Object} props.decimalInputRefs - React ref with the array of refs for the input forms to update its values.
 * @param {Object} props.formFunctions - Object with functions used in the input/select fields.
 * @returns {JSX.Element} Returns the JSX component to render
 */
const CalculatorForm = ({
  ratesData = [
    {
      LoanProgram: "30-Year Fixed Mortgage",
      InterestRate: 6.875,
      APR: 7.091,
      Fee: 1.125,
      PIPayments: 2102.17,
      LoanTerms: ["30 Year Fixed"],
      LoanType: "Default-Special"
    }
  ], // This is just an example we have all in the rates API.
  loanType = "Home Loan",
  isSpanish = false,
  FORM_INITIAL_VALUES = {},
  FORM_SET_STATES = { inputId: () => {} },
  setLoanTerm= () => {},
  decimalInputRefs,
  formFunctions
}) => {
  const { handleOnChange, handleOnBlur } = formFunctions;

  const [homeValueFieldName, setHomeValueFieldName] = useState("");
  const [mortgageFieldName, setMortgageFieldName] = useState("");
  const [cashOutFieldName, setCashOutFieldName] = useState("");
  const loanAmountFieldName = isSpanish ? "Monto del préstamo" : "Loan Amount";
  const loanTermSelectedLabel = isSpanish ? "Plazo del prestamo" : "Loan Term";
  const interestRateLabel = isSpanish ? "Tasa de interés" : "Interest Rate";
  const downPaymentAmountLabel = isSpanish ? "Depósito" : "Down Payment";
  const downPaymentPercentageLabel = isSpanish ? "Porcentaje" : "Percentage Down";

  useEffect(() => {
    switch (loanType) {
      case "Home Loan":
        setMortgageFieldName(isSpanish ? "Precio de compra" : "Purchase Price");
        break;
      case "Lot Loan":
        setMortgageFieldName(isSpanish ? "Precio del terreno" : "Land Value");
        break;
      case "Remodeling":
        setHomeValueFieldName("Existing Home Value");
        setMortgageFieldName("Remodeling Budget");
        break;
      case "Construction":
        setHomeValueFieldName('Land Value "as is"');
        setMortgageFieldName("Construction Budget");
        break;
      case "HELOC":
        setHomeValueFieldName("Home Value");
        setMortgageFieldName("Existing Mortgage");
        setCashOutFieldName("Equity Credit Line");
        break;
      case "Refinance":
        setHomeValueFieldName("Home Value");
        setMortgageFieldName("Existing Mortgage");
        setCashOutFieldName("Cash Out?*");
        break;
      case "HELOAN":
        setHomeValueFieldName("Home Value");
        setMortgageFieldName("Existing Mortgage");
        break;
      default:
    }
  }, []);

  const PERCENTAGE_DIGITS_LIMIT = 2;
  const PERCENTAGE_DECIMALS_LIMIT = 3;
  const CURRENCY_DIGITS_LIMIT = 9;
  const CURRENCY_DECIMALS_LIMIT = 2;

  const INPUT_DIGITS_LIMIT = {
    "purchase-price": { maxDigits: CURRENCY_DIGITS_LIMIT, maxDecimals: CURRENCY_DECIMALS_LIMIT },
    "home-value-amount": { maxDigits: CURRENCY_DIGITS_LIMIT, maxDecimals: CURRENCY_DECIMALS_LIMIT },
    "loan-amount": { maxDigits: CURRENCY_DIGITS_LIMIT, maxDecimals: CURRENCY_DECIMALS_LIMIT },
    "interest-rate": { maxDigits: PERCENTAGE_DIGITS_LIMIT, maxDecimals: PERCENTAGE_DECIMALS_LIMIT },
    "down-payment-amount": { maxDigits: CURRENCY_DIGITS_LIMIT, maxDecimals: CURRENCY_DECIMALS_LIMIT },
    "down-payment-percentage": { maxDigits: PERCENTAGE_DIGITS_LIMIT, maxDecimals: PERCENTAGE_DECIMALS_LIMIT },
    "cash-out-amount": { maxDigits: CURRENCY_DIGITS_LIMIT, maxDecimals: CURRENCY_DECIMALS_LIMIT }
  };

  const INPUT_LABELS = {
    "purchase-price": mortgageFieldName,
    "home-value-amount": homeValueFieldName,
    "loan-amount": loanAmountFieldName,
    "interest-rate": interestRateLabel,
    "down-payment-amount": downPaymentAmountLabel,
    "down-payment-percentage": downPaymentPercentageLabel,
    "cash-out-amount": cashOutFieldName
  };

  const loanAmountDisabled = loanType !== "Remodeling" && loanType !== "Construction" && loanType !== "HELOAN";

  /**
   * This function return the params object for the component sent.
   *
   * @param {string} category - Category of component "input" || "select" || "custom".
   * @param {string} id - Assigned ID for the component.
   * @param {string} name - Name to use in smart objects related to the component, id="purchase-price" we use name="purchasePrice".
   * @returns {Object} Returns an Object with the params for the component
   */
  const componentParams = (category, id, name) => {
    if (category === "input") {
      const isPercentageInput = id.toLowerCase().includes("percent") || id.toLowerCase().includes("rate");
      return {
        componentCategory: category,
        id: id,
        label: INPUT_LABELS[id],
        name: name,
        initialValue: FORM_INITIAL_VALUES[id],
        startCharacter: isPercentageInput ? "%" : "$",
        disabled: id === "loan-amount" ? loanAmountDisabled : false,
        maxDigits: INPUT_DIGITS_LIMIT[id].maxDigits,
        maxDecimals: INPUT_DIGITS_LIMIT[id].maxDecimals,
        onChange: (event) => handleOnChange(event),
        customOnBlur: () => handleOnBlur()
      };
    }

    if (category === "select") {
      // console.log("LOAN TYPE", loanType);
      const loanTerms =
        ratesData.find((rate) => rate.LoanType === loanType || rate.LoanType === "Default-Special")?.LoanTerms || [];

      // console.log("THE LOAN TERMS", loanTerms);
      return {
        componentCategory: category,
        id: id,
        labelText: loanTermSelectedLabel,
        initialValue: FORM_INITIAL_VALUES[id],
        onChange: (event) => handleOnChange(event),
        disabled: loanTerms.length === 1,
        options: loanTerms
      };
    }

    return null;
  };

  const INPUTS_LIST = {
    "purchase-price": {
      ...componentParams("input", "purchase-price", "purchasePrice")
    },
    "home-value-amount": {
      ...componentParams("input", "home-value-amount", "homeValueAmount")
    },
    "loan-amount": {
      ...componentParams("input", "loan-amount", "loanAmount")
    },
    "interest-rate": {
      ...componentParams("input", "interest-rate", "interestRate")
    },
    "down-payment-amount": {
      ...componentParams("input", "down-payment-amount", "downPaymentAmount")
    },
    "down-payment-percentage": {
      ...componentParams("input", "down-payment-percentage", "downPaymentPercentage")
    },
    "cash-out-amount": {
      ...componentParams("input", "cash-out-amount", "cashOutAmount")
    }
  };

  const SELECTS_LIST = {
    "dd-loan-term": {
      ...componentParams("select", "dd-loan-term", "")
    }
  };

  const CUSTOM_COMPONENTS_LIST = {
    "equal-character": {
      componentCategory: "custom",
      component: () => (
        <div
          className="d-none d-md-flex align-items-center justify-content-center"
          style={{
            position: "absolute",
            top: 0,
            right: -16,
            bottom: 0
          }}
        >
          <h5 className="mt-4">=</h5>
        </div>
      )
    }
  };

  const ComponentNotFound = () => <div>Component Not Found</div>;

  const FORM_ROWS_BY_LOAN_TYPE = {
    "Home Loan": [
      {
        rowInputs: [INPUTS_LIST["purchase-price"], SELECTS_LIST["dd-loan-term"]]
      },
      {
        rowInputs: [INPUTS_LIST["down-payment-amount"], INPUTS_LIST["down-payment-percentage"]]
      },
      {
        rowInputs: [INPUTS_LIST["loan-amount"], INPUTS_LIST["interest-rate"]]
      }
    ],
    "Lot Loan": [
      {
        rowInputs: [INPUTS_LIST["purchase-price"], SELECTS_LIST["dd-loan-term"]]
      },
      {
        rowInputs: [INPUTS_LIST["down-payment-amount"], INPUTS_LIST["down-payment-percentage"]]
      },
      {
        rowInputs: [INPUTS_LIST["loan-amount"], INPUTS_LIST["interest-rate"]]
      }
    ],
    Remodeling: [
      {
        rowInputs: [INPUTS_LIST["home-value-amount"], INPUTS_LIST["loan-amount"]]
      },
      {
        rowInputs: [SELECTS_LIST["dd-loan-term"], INPUTS_LIST["interest-rate"]]
      }
    ],
    Construction: [
      {
        rowInputs: [INPUTS_LIST["home-value-amount"], INPUTS_LIST["loan-amount"]]
      },
      {
        rowInputs: [SELECTS_LIST["dd-loan-term"], INPUTS_LIST["interest-rate"]]
      }
    ],
    HELOC: [
      {
        rowInputs: [INPUTS_LIST["home-value-amount"], INPUTS_LIST["purchase-price"]]
      },
      {
        rowInputs: [INPUTS_LIST["cash-out-amount"], SELECTS_LIST["dd-loan-term"]]
      },
      {
        rowInputs: [INPUTS_LIST["interest-rate"]]
      }
    ],
    Refinance: [
      {
        rowInputs: [INPUTS_LIST["home-value-amount"], SELECTS_LIST["dd-loan-term"]]
      },
      {
        rowInputs: [INPUTS_LIST["purchase-price"], INPUTS_LIST["cash-out-amount"]]
      },
      {
        rowInputs: [INPUTS_LIST["loan-amount"], INPUTS_LIST["interest-rate"]]
      }
    ],
    HELOAN: [
      {
        rowInputs: [INPUTS_LIST["home-value-amount"], INPUTS_LIST["purchase-price"]]
      },
      {
        rowInputs: [INPUTS_LIST["loan-amount"], SELECTS_LIST["dd-loan-term"]]
      },
      {
        rowInputs: [INPUTS_LIST["interest-rate"]]
      }
    ]
  };

  const FormRows = () => {
    return (
      <div className="row position-relative">
        {FORM_ROWS_BY_LOAN_TYPE[loanType]?.map((formRows) => {
          {
            /* console.log("ROW INPUTS:", formRows.rowInputs); */
          }
          return formRows.rowInputs.map((componentData, idx) => {
            {
              /* console.log("COMPONENT DATA:", componentData); */
            }
            if (componentData) {
              const { componentCategory, ...componentProps } = componentData;

              if (componentCategory === "input") {
                return (
                  <DecimalInput
                    setNumericValueState={FORM_SET_STATES[componentProps.id]}
                    decimalInputRef={decimalInputRefs.current[componentProps.id]}
                    EndCustomComponent={
                      componentProps.id === "down-payment-amount"
                        ? CUSTOM_COMPONENTS_LIST["equal-character"].component
                        : null
                    }
                    {...componentProps}
                  />
                );
              }

              if (componentCategory === "select") {
                const { labelText, ...selectProps } = componentProps;
                return (
                  <MemoizedSelectInput
                    setLoanTerm={setLoanTerm}
                    selectRef={decimalInputRefs.current[componentProps.id]}
                    key={idx}
                    labelText={labelText}
                    selectProps={selectProps}
                  />
                );
              }

              if (componentCategory === "custom") {
                const { component } = componentProps;
                return component;
              }
            }

            return <ComponentNotFound />;
          });
        })}
      </div>
    );
  };

  return (
    <div className="col-lg-6 order-1 mb-3 mb-lg-4">
      <form id="mortgage-calculator-form" className={styles.calc}>
        <FormRows />
      </form>
    </div>
  );
};

export default React.memo(CalculatorForm, () => true);
// export default CalculatorForm;

CalculatorForm.propTypes = {
  ratesData: PropTypes.arrayOf(PropTypes.object).isRequired,
  loanType: (props, propName, componentName) => {
    // Valid values example ["Home Loan","Refinance","Construction","Lot Loan","HELOC","Smart Start","HELOAN","Remodeling"]
    if (props.ratesData.length) {
      const validValues = props.ratesData.map((rate) => rate.LoanType);

      if (!validValues.includes(props[propName])) {
        return new Error(
          `Invalid prop value ${propName}="${props[propName]}" supplied to ${componentName}.` +
            `\n\nExpected values: ${JSON.stringify(validValues)}.\n\nValidation failed.`
        );
      }
    }
  },
  isSpanish: PropTypes.bool,
  FORM_INITIAL_VALUES: PropTypes.object.isRequired,
  FORM_SET_STATES: PropTypes.object.isRequired,
  setLoanTerm: PropTypes.func.isRequired,
  decimalInputRefs: PropTypes.object.isRequired,
  formFunctions: PropTypes.object.isRequired
};
