import React, { useEffect, useState } from "react";
import { PieChart, Pie, Cell } from "recharts";
import PropTypes from "prop-types";

import PieChartLabel from "./pie-chart-label";
import styles from "./calculator.module.scss";

/**
 * This is the component to render the Monthly Payment Info as Chart and its categories or just the amount and its text.
 *
 * @param {Object} props - The object containing props.
 * @param {Object[]} props.ratesData - Rates Data from the API is used for the propTypes validations.
 * @param {boolean} props.showTaxesAndInsurance - If we add the taxes and insurance within the chart and the total amount this should be true.
 * @param {string} props.estimatedMonthlyPaymentFormatted - Formatted currency amount of the calculated monthly payment.
 * @param {number} props.estimatedMonthlyPaymentAmount - Amount of the calculated monthly payment.
 * @param {number} props.propertyTaxAmount - (Home Value or Purchase Price) * defaultPropertyTaxRate.
 * @param {number} props.homeownersInsuranceAmount - (Home Value or Purchase Price) * homeownerInsurancePercent.
 * @param {string} props.loanType - Loan Type like "Home Loan", "Remodeling".
 * @param {boolean} props.IS_LOAN_TYPE_INTEREST_ONLY - Whether the loan type is interest only or not.
 * @param {boolean} props.isSpanish - If we are in a spanish page.
 * @returns {React.JSX.Element} Returns the JSX component to render
 */
const MonthlyPaymentInfo = ({
  ratesData = [
    {
      LoanProgram: "30-Year Fixed Mortgage",
      InterestRate: 6.875,
      APR: 7.091,
      Fee: 1.125,
      PIPayments: 2102.17,
      LoanTerms: ["30 Year Fixed"],
      LoanType: "Default-Special"
    }
  ], // This is just an example we have all in the rates API.
  showTaxesAndInsurance = false,
  estimatedMonthlyPaymentFormatted = "1,234.56",
  estimatedMonthlyPaymentAmount = 1234.56,
  propertyTaxAmount = 0,
  homeownersInsuranceAmount = 0,
  loanType = "Home Loan",
  IS_LOAN_TYPE_INTEREST_ONLY = false,
  isSpanish = false
}) => {
  const getLocaleText = (text) => {
    const language = isSpanish ? "es" : "en";
    const localeTextDic = {
      en: {
        interestOnly: "Interest Only",
        principalInterest: "Principal & Interest",
        Taxes: "Taxes",
        Insurance: "Insurance"
      },
      es: {
        interestOnly: "Solo Interes",
        principalInterest: "De capital e intereses",
        Taxes: "Impuestos",
        Insurance: "Seguro"
      }
    };

    return localeTextDic[language][text] || localeTextDic["en"][text];
  };

  const numberFormatter = new Intl.NumberFormat("en", {
    maximumFractionDigits: 2,
    minimumFractionDigits: 0,
    style: "currency",
    currency: "USD"
  });

  const chartColors = [styles.white, styles.green20, styles.green40];

  // This is the information to divide the chart sections into the needed categories
  const [chartData, setChartData] = useState([
    { name: "Principal & Interest", value: 10 },
    { name: "Taxes", value: 10 },
    { name: "Insurance", value: 10 }
  ]);

  useEffect(() => {
    setChartData([
      { name: "Principal & Interest", value: estimatedMonthlyPaymentAmount },
      { name: "Taxes", value: propertyTaxAmount },
      { name: "Insurance", value: homeownersInsuranceAmount }
    ]);
  }, [estimatedMonthlyPaymentAmount, propertyTaxAmount, homeownersInsuranceAmount]);

  const CHART_CATEGORIES = [
    {
      id: "principal-interest-amount",
      title:
        loanType === "HELOC" || (loanType === "Lot Loan" && IS_LOAN_TYPE_INTEREST_ONLY)
          ? getLocaleText("interestOnly")
          : getLocaleText("principalInterest"),
      amount: estimatedMonthlyPaymentFormatted,
      bgClass: "bg-white"
    },
    {
      id: "taxes-amount",
      title: getLocaleText("Taxes"),
      amount: propertyTaxAmount,
      bgClass: "bg-green-20"
    },
    {
      id: "insurance-amount",
      title: getLocaleText("Insurance"),
      amount: homeownersInsuranceAmount,
      bgClass: "bg-green-40"
    }
  ];

  const ChartCategoryListItem = ({ id, title, amount, bgClass, lastItem }) => {
    return (
      <li {...(lastItem ? { className: "mb-0" } : {})}>
        <p className="h5-font-size mb-0">{title}</p>
        <div className={"row align-items-center mx-0" + (!lastItem ? " mb-4" : "")}>
          <p id={id} className="h5-font-size font-weight-bold mb-0 mr-2">
            ${amount}
          </p>
          <div id={id + "-circle"} className={`${styles.circle16} ${bgClass}`} />
        </div>
      </li>
    );
  };

  // Estimated Monthly Payment Description
  const EMP_DESCRIPTION = isSpanish
    ? loanType === "HELOC"
      ? "Pago HELOC mensual estimado"
      : "Pago mensual estimado"
    : loanType === "HELOC"
    ? "Estimated HELOC Monthly Payment"
    : "Estimated Monthly Payment";

  const EMP_CURRENCY = showTaxesAndInsurance
    ? numberFormatter.format(estimatedMonthlyPaymentAmount + propertyTaxAmount + homeownersInsuranceAmount)
    : estimatedMonthlyPaymentFormatted;

  // There is just 2 different charts one for bigger spaces and one that fits on smaller spaces
  const PIE_CHART_PROPS = {
    large: {
      chartContainer: "d-none d-sm-block d-md-none d-lg-block position-relative col-8 col-sm-7",
      pieChartSize: {
        width: 228,
        height: 228
      },
      pieProps: {
        cx: 110,
        cy: 110,
        innerRadius: 92,
        outerRadius: 112
      },
      pieChartLabelProps: {
        maxWidth: 228,
        maxHeight: 228,
        label: EMP_DESCRIPTION,
        amount: EMP_CURRENCY
      }
    },
    small: {
      chartContainer: "d-block d-sm-none d-md-block d-lg-none position-relative col-7 px-0",
      pieChartSize: {
        width: 155,
        height: 155
      },
      pieProps: {
        cx: 73,
        cy: 73,
        innerRadius: 60,
        outerRadius: 75
      },
      pieChartLabelProps: {
        maxWidth: 155,
        maxHeight: 155,
        textSmall: true,
        label: EMP_DESCRIPTION,
        amount: EMP_CURRENCY
      }
    }
  };

  const PieChartWithProps = () => {
    const { large, small } = PIE_CHART_PROPS;

    const PieChartCells = chartData.map((entry, index) => <Cell key={`cell-${index}`} fill={chartColors[index]} />);

    const PIE_DEFAULT_PROPS = {
      data: chartData,
      fill: styles.green60,
      paddingAngle: 0,
      dataKey: "value",
      stroke: "none"
    };

    return (
      <>
        <div className={large.chartContainer}>
          <PieChart {...large.pieChartSize}>
            <Pie {...PIE_DEFAULT_PROPS} {...large.pieProps}>
              {PieChartCells}
            </Pie>
          </PieChart>
          <PieChartLabel {...large.pieChartLabelProps} />
        </div>
        <div className={small.chartContainer}>
          <PieChart {...small.pieChartSize}>
            <Pie {...PIE_DEFAULT_PROPS} {...small.pieProps}>
              {PieChartCells}
            </Pie>
          </PieChart>
          <PieChartLabel {...small.pieChartLabelProps} />
        </div>
      </>
    );
  };

  return showTaxesAndInsurance ? (
    <>
      {/* CHART INFO */}
      <div className="col-md-6 order-2 order-lg-3 mb-4 mb-lg-0">
        <div className="bg-green-60 border-radius-12 p-3 py-4">
          <div className="row">
            <div className="col-5 col-lg-4 col-xl-5">
              <ul className="text-white list-unstyled mb-0">
                {
                  // I validate if is not HELOC we show all the categories, if not we show just the first one
                  (loanType !== "HELOC" ? CHART_CATEGORIES : [CHART_CATEGORIES[0]]).map((chartCategory, idx) => {
                    const lastItem = idx === CHART_CATEGORIES.length - 1;
                    return <ChartCategoryListItem key={chartCategory.id} {...chartCategory} lastItem={lastItem} />;
                  })
                }
              </ul>
            </div>

            <PieChartWithProps />
          </div>
        </div>
      </div>
    </>
  ) : (
    <>
      {/* MONTHLY PAYMENT INFO */}
      <div className="col-md-6 order-2 order-lg-3 mb-4 mb-lg-0">
        <div className="bg-green-60 border-radius-12 p-3 py-4 text-white text-center h-100 d-flex flex-column justify-content-center justify-content-lg-start">
          <h3>{EMP_DESCRIPTION}</h3>
          <div className="display-4 mb-0">{EMP_CURRENCY}</div>
        </div>
      </div>
    </>
  );
};

export default MonthlyPaymentInfo;

MonthlyPaymentInfo.propTypes = {
  ratesData: PropTypes.arrayOf(PropTypes.object).isRequired,
  showTaxesAndInsurance: PropTypes.bool.isRequired,
  estimatedMonthlyPaymentFormatted: PropTypes.string.isRequired,
  estimatedMonthlyPaymentAmount: PropTypes.number.isRequired,
  propertyTaxAmount: PropTypes.number,
  homeownersInsuranceAmount: PropTypes.number,
  loanType: (props, propName, componentName) => {
    // Valid values example ["Home Loan","Refinance","Construction","Lot Loan","HELOC","Smart Start","HELOAN","Remodeling"]
    if (props.ratesData.length) {
      const validValues = props.ratesData.map((rate) => rate.LoanType);

      if (!validValues.includes(props[propName])) {
        return new Error(
          `Invalid prop value ${propName}="${props[propName]}" supplied to ${componentName}.` +
            `\n\nExpected values: ${JSON.stringify(validValues)}.\n\nValidation failed.`
        );
      }
    }
  },
  IS_LOAN_TYPE_INTEREST_ONLY: PropTypes.bool,
  isSpanish: PropTypes.bool
};
