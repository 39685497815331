import React from "react";
import PropTypes from "prop-types";
import NotificationAlert from "../../notifications/notification-alert";

/**
 * This is the component to render disclaimers below the calculator.
 *
 * @param {Object} props - The object containing props.
 * @param {Object[]} props.ratesData - Rates Data from the API is used for the propTypes validations.
 * @param {string} props.loanType - Loan Type like "Home Loan", "Remodeling".
 * @param {boolean} props.isSpanish - If we are in a spanish page.
 * @param {boolean} props.IS_LOAN_TYPE_INTEREST_ONLY - Whether is loan type interest only.
 * @param {string} props.sectionClass - Section classes to apply for the section element.
 * @returns {JSX.Element} Returns the JSX component to render
 */
const MortgageCalculatorDisclaimers = ({
  ratesData,
  loanType,
  isSpanish,
  IS_LOAN_TYPE_INTEREST_ONLY,
  sectionClass
}) => {
  const isRefinanceType = loanType === "Refinance";
  // const isLotLoanType = loanType === "Lot Loan";
  // const isHelocType = loanType === "HELOC";

  const HeloanCalculatorDisclaimers = () => {
    const discData1 = [
      "Fixed interest rate and APR (Annual Percentage Rate) are set subject to credit approval of your credit history, loan to value and occupancy.",
      "Monthly interest only payments do not include property taxes and insurance.",
      "Consult your tax advisor.",
      "HELOANs available for owner occupied homes, second homes & investment properties.",
      "For 2nd lien position only.",
      "Closing costs are paid only for primary homes, not for second, vacation, or investment properties.*",
      "Fees Range: $354 - $7,579. Your HELOAN fees are included in the disclosure provided at loan application and again at closing."
    ];
    const discData2 = [
      "Home Equity Loan Calculator payments display monthly principal and interest payments assuming the original loan balance displayed in the Loan Amount Line.",
      "HELOANs feature terms of 5, 10, 15 and 20 years.",
      "Property insurance is required.",
      "Loans are subject to credit approval. Restrictions and limitations may apply.",
      "This calculator is for educational purposes only and may not represent WaFd Bank's lending program requirements.",
      "Fees for our HELOAN vary depending on the loan amount and property location.",
      "Payments quoted do not include taxes & insurance, so actual payments may be higher."
    ];

    return (
      <div className="text-sm text-gray-60">
        <div className="row">
          <div className="col-md-6">
            <ul>
              {discData1.map((disc, index) => {
                return <li key={index}>{disc}</li>;
              })}
            </ul>
          </div>
          <div className="col-md-6">
            <ul>
              {discData2.map((disc, index) => {
                return <li key={index}>{disc}</li>;
              })}
            </ul>
          </div>
        </div>
      </div>
    );
  };

  return (
    <section id="mortgage-calculator-disclaimer-section" className={"container " + sectionClass}>
      {loanType !== "HELOAN" && loanType !== "HELOC" && loanType !== "Lot Loan" && loanType !== "Home Loan" && (
        <div className="text-gray-60 text-sm">
          <div className="row">
            <div className="col-lg-6">
              <ul className="mb-0 list-unstyled">
                {isRefinanceType && <li>*Cash refinances are subject to conditions.</li>}
                {isSpanish ? (
                  <li className="mb-lg-0">
                    Esta calculadora tiene fines educativos únicamente y es posible que no represente los requisitos del
                    programa de préstamos de WaFd Bank. Los pagos cotizados no incluyen impuestos ni seguros, por lo que
                    los pagos reales pueden ser mayores.
                  </li>
                ) : (
                  <li className="mb-lg-0">
                    This calculator is for educational purposes only, and may not represent WaFd Bank's lending program
                    requirements. Payments quoted do not include taxes & insurance, so actual payments may be higher.
                  </li>
                )}
              </ul>
            </div>
            <div className="col-lg-6">
              <ul className="mb-0 list-unstyled">
                {isSpanish ? (
                  <li className="mb-lg-0">
                    Los préstamos están sujetos a la aprobación del crédito. Pueden aplicarse restricciones y
                    limitaciones.
                  </li>
                ) : (
                  <li className="mb-lg-0">
                    Loans are subject to credit approval. Restrictions and limitations may apply. Rates for owner
                    occupied homes only.
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
      )}

      {loanType === "HELOAN" && <HeloanCalculatorDisclaimers />}

      {loanType === "Home Loan" && (
        <div className="text-gray-60 text-sm">
          <div className="row">
            <div className="col-lg-6">
              <ul className="mb-0 list-unstyled">
                {isSpanish ? (
                  <li className="mb-lg-0">
                    Los pagos cotizados no incluyen impuestos ni seguros, por lo que los pagos reales pueden ser
                    mayores. Los préstamos están sujetos a aprobación de crédito. Aplican restricciones.
                  </li>
                ) : (
                  <li className="mb-lg-0">
                    Payments quoted do not include taxes & insurance, so actual payments may be higher. Loans are
                    subject to credit approval. Restrictions and limitations may apply.
                  </li>
                )}
              </ul>
            </div>
            <div className="col-lg-6">
              <ul className="mb-0 list-unstyled">
                {isSpanish ? (
                  <li className="mb-0">
                    Tasas exclusivas para casas ocupadas. Esta calculadora tiene propósitos educativos exclusivamente, y
                    puede no representar los requerimientos de los programas de préstamos de WaFd Bank.
                  </li>
                ) : (
                  <li className="mb-0">
                    Rates for owner occupied homes only. This calculator is for educational purposes only, and may not
                    represent WaFd Bank's lending program requirements.
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
      )}

      {loanType === "Lot Loan" && (
        <div className="text-gray-60 text-sm">
          <div className="row">
            <div className="col-lg-6">
              <ul className="mb-0 list-unstyled">
                {isSpanish ? (
                  <li className="mb-lg-0">
                    APR es Porcentaje de Tasa Anual. Los pagos cotizados no incluyen impuestos ni seguros, por lo que
                    los pagos reales pueden ser mayores. Los préstamos están sujetos a aprobación de crédito. Aplican
                    restricciones. Tasas exclusivas para primera residencia o segundo hogar.
                  </li>
                ) : (
                  <li className="mb-lg-0">
                    APR is Annual Percentage Rate. Payments quoted do not include taxes & insurance, so actual payments
                    may be higher. Loans are subject to credit approval. Restrictions and limitations may apply. Rates
                    for intended use of primary residence or second home.
                  </li>
                )}
              </ul>
            </div>
            <div className="col-lg-6">
              <ul className="mb-0 list-unstyled">
                {isSpanish ? (
                  <li className="mb-lg-0">
                    Esta calculadora tiene fines educativos únicamente y es posible que no represente los requisitos del
                    programa de préstamos de WaFd Bank. Descuento del 2% en la tasa de interés disponible en préstamos
                    para lotes a 20 años para prestatarios con un pago inicial del 50% y un puntaje FICO de al menos
                    720. Comuníquese con el oficial de préstamos de su vecindario para obtener más información.
                  </li>
                ) : (
                  <li className="mb-lg-0">
                    This calculator is for educational purposes only, and may not represent WaFd Bank's lending program
                    requirements. 2% interest rate discount available on 20 year lot loans for borrowers with a down
                    payment of 50% and a FICO score of at least 720. Contact your neighborhood loan officer to learn
                    more.
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
      )}

      {loanType === "HELOC" && (
        <div className="row text-sm text-gray-60">
          <div className="col-lg-6">
            <p>
              NO ANNUAL FEE. Discount of 0.250% off your interest rate for automatic monthly payment from your WaFd
              Checking Account. Interest rate and APR (Annual Percentage Rate) are variable based on the Wall Street
              Journal Prime Rate plus a margin subject to credit approval of your credit history, loan to value,
              occupancy and EZ Pay requirements. Payments quoted do not include taxes & insurance, so actual payments
              may be higher. Consult your tax advisor. The rate will never exceed 17.99% APR. HELOCs available for owner
              occupied homes, second homes & investment properties. Closing costs for our existing WaFd home loan
              clients are paid only for primary or second homes, not for vacation or investment properties.* Fees Range:
              $354 - $7,579. Your HELOC fees are included in the disclosure provided at loan application and again at
              closing. Home Equity Loan Calculator payments display monthly interest only payments assuming the
              outstanding balance displayed in the Equity Credit Line.{" "}
            </p>
          </div>
          <div className="col-lg-6">
            <p>HELOCs feature a 25 year term as follows:</p>
            <ul>
              <li>10 year draw period to borrow on your line of credit whenever you need it</li>
              <li>
                Any outstanding loan balance after 10 year draw period will be amortized (principal and interest
                payments) over 15 years
              </li>
            </ul>
            <p>
              Property Insurance is required. Investment properties require WaFd to be in first lien position. Loans are
              subject to credit approval. Restrictions and limitations may apply. This calculator is for educational
              purposes only, and may not represent WaFd Bank's lending program requirements. Fees for our HELOC vary
              depending on the HELOC loan amount and property location.
            </p>
          </div>
        </div>
      )}

      {loanType === "HELOC" || (loanType === "Lot Loan" && IS_LOAN_TYPE_INTEREST_ONLY) ? (
        <NotificationAlert
          type="primary"
          class="my-0"
          id="interest-only-alert"
          showIcon={false}
          bodyText={
            isSpanish
              ? "Su pago hipotecario estimado de solo intereses para el período de solo intereses no tiene en cuenta los pagos de principal que realizará más adelante, cuando el préstamo comience a amortizarse. Comuníquese con uno de nuestros amables agentes de préstamos para obtener más información e información de pago precisa."
              : "Your estimated interest-only mortgage payment for the interest-only period doesn't account for the principal payments you'll make later when the loan begins amortizing. Contact one of our friendly loan officers for more information and accurate payment information."
          }
        />
      ) : null}
    </section>
  );
};
export default MortgageCalculatorDisclaimers;

MortgageCalculatorDisclaimers.defaultProps = {
  ratesData: [
    {
      LoanProgram: "30-Year Fixed Mortgage",
      InterestRate: 6.875,
      APR: 7.091,
      Fee: 1.125,
      PIPayments: 2102.17,
      LoanTerms: ["30 Year Fixed"],
      LoanType: "Default-Special"
    }
  ], // This is just an example we have all in the rates API.
  loanType: "Home Loan",
  isSpanish: false,
  IS_LOAN_TYPE_INTEREST_ONLY: false,
  sectionClass: "pt-0"
};

MortgageCalculatorDisclaimers.propTypes = {
  ratesData: PropTypes.arrayOf(PropTypes.object).isRequired,
  loanType: (props, propName, componentName) => {
    // Valid values example ["Home Loan","Refinance","Construction","Lot Loan","HELOC","Smart Start","HELOAN","Remodeling"]
    if (props.ratesData.length) {
      const validValues = props.ratesData.map((rate) => rate.LoanType);

      if (!validValues.includes(props[propName])) {
        return new Error(
          `Invalid prop value ${propName}="${props[propName]}" supplied to ${componentName}.` +
            `\n\nExpected values: ${JSON.stringify(validValues)}.\n\nValidation failed.`
        );
      }
    }
  },
  isSpanish: PropTypes.bool,
  IS_LOAN_TYPE_INTEREST_ONLY: PropTypes.bool,
  sectionClass: PropTypes.string
};
