/* eslint-disable no-console */
import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import axios from "axios";

import useFormattedNumber from "../../../hooks/use-formatted-number";
import MortgageCalculatorDisclaimers from "./calculator-disclaimers";
import OtherLoanCalculatorsLinks from "./other-loan-calculators-links";
import APRRateInfo from "./apr-rate-info";
import MonthlyPaymentInfo from "./monthly-payment-info";
import CalculatorForm from "./calculator-form";
import { getSetInputValue, getSetSelectValue, getSetInputError } from "../../inputs/call-input-function";

//Styles
import styles from "./calculator.module.scss";

/**
 * Sets the display title for the mortgage calculator
 * 
 * @param {Object} props - The object containing props.
 * @param {boolean} props.isTitleH1 - If set, will be displayed as a `h1` otherwise will be as `h3`
 * @param {string} props.title - The title to be displayed
 * @param {boolean} props.isSpanish - Current page language
 */
function MortgageCalculatorTitle({ isTitleH1, title, isSpanish }) {
  const titleSuffix = isSpanish ? "" : " Calculator";
  const content = typeof title === "string" ? title + titleSuffix : "Mortgage Calculator";
  return isTitleH1 ? (
    <h1 className="font-weight-semi-bold text-green-60">{content}</h1>
  ) : (
    <h3 className="font-weight-semi-bold text-green-60">{content}</h3>
  );
}

/**
 * This is the component to render the Mortgage Calculator.
 *
 * @param {Object} props - The object containing props.
 * @param {boolean} props.isTitleH1 - Whether the tile should be H1 element or not.
 * @param {string} props.title - Title for the calculator shown in green.
 * // NOTE: This array of Loan Types must be updated when we update the Loan Types in the API
 * @param {"Home Loan" | "Refinance" | "Construction" | "Lot Loan" | "HELOC" | "Smart Start" | "HELOAN" | "Remodeling" } props.loanType - Loan Type like "Home Loan", "Remodeling".
 * @param {number} props.homeValue - Pre assigned/filled value for the input.
 * @param {number} props.interestRate - Pre assigned/filled value for the input.
 * @param {number} props.purchasePrice - Pre assigned/filled value for the input.
 * @param {boolean} props.cashOutAmount - Pre assigned/filled value for the input.
 * @param {boolean} props.downPaymentPercent - Pre assigned/filled value for the input.
 * @param {boolean} props.downPaymentAmount - Pre assigned/filled value for the input.
 * @param {boolean} props.loanAmount - Pre assigned/filled value for the input.
 * @param {boolean} props.isSpanish - If we are in a spanish page.
 * @param {string} props.disclaimersSectionClass - If we want to modify the section classes we can do it in here like "pt-0"
 * @param {boolean} props.showTaxesAndInsurance - If we add the taxes and insurance within the chart and the total amount this should be true.
 * @returns {React.JSX.Element} Returns the JSX component to render
 */
const NewMortgageCalculator = ({
  isTitleH1: propIsTitleH1 = false,
  title: propTitle = "First Time Home Buyer Loan",
  loanType = "Home Loan",
  loanTerm: propLoanTerm = "30 Year Fixed",
  homeValue: propHomeValue = 0,
  interestRate: propInterestRate = 0,
  estimatedPayment: propEstimatedPayment = 0,
  purchasePrice: propPurchasePrice = 0,
  cashOutAmount: propCashOutAmount = 0,
  downPaymentPercent: propDownPaymentPercent = 0,
  downPaymentAmount: propDownPaymentAmount = 0,
  loanAmount: propLoanAmount = 0,
  isSpanish = false,
  disclaimersSectionClass = "pt-0",
  showTaxesAndInsurance = false
}) => {
  const defaultPropertyTaxRate = 0.768333;
  const defaultPropertyTaxAmount = propHomeValue
    ? parseInt(propHomeValue * defaultPropertyTaxRate * 0.01, 10)
    : parseInt(propPurchasePrice * defaultPropertyTaxRate * 0.01, 10);
  const defaultHomeownerInsuranceRate = 1.08;
  const defaultHomeownerInsuranceAmount = propHomeValue
    ? parseInt(propHomeValue * defaultHomeownerInsuranceRate * 0.01, 10)
    : parseInt(propPurchasePrice * defaultHomeownerInsuranceRate * 0.01, 10);

  const [purchasePrice, setPurchasePrice] = useState(propPurchasePrice);
  const [loanAmount, setLoanAmount] = useState(propLoanAmount);
  const [cashOutAmount, setCashOutAmount] = useState(propCashOutAmount);
  const [downPaymentAmount, setDownPaymentAmount] = useState(propDownPaymentAmount);
  const [downPaymentPercentage, setDownPaymentPercentage] = useState(propDownPaymentPercent);
  const [interestRate, setInterestRate] = useState(propInterestRate);
  const [homeValueAmount, setHomeValueAmount] = useState(propHomeValue);
  const [estimatedMonthlyPayment, setEstimatedMonthlyPayment] = useState(propEstimatedPayment);
  const [loanTerm, setLoanTerm] = useState(propLoanTerm);

  const FORM_INITIAL_VALUES = {
    values: {
      "purchase-price": propPurchasePrice,
      "loan-amount": propLoanAmount,
      "cash-out-amount": propCashOutAmount,
      "down-payment-amount": propDownPaymentAmount,
      "down-payment-percentage": propDownPaymentPercent,
      "interest-rate": propInterestRate,
      "home-value-amount": propHomeValue,
      "dd-loan-term": propLoanTerm
    },
    setStates: {
      "purchase-price": setPurchasePrice,
      "loan-amount": setLoanAmount,
      "cash-out-amount": setCashOutAmount,
      "down-payment-amount": setDownPaymentAmount,
      "down-payment-percentage": setDownPaymentPercentage,
      "interest-rate": setInterestRate,
      "home-value-amount": setHomeValueAmount,
      "dd-loan-term": setLoanTerm
    }
  };

  const [propertyTaxPercent] = useState(defaultPropertyTaxRate);
  const [propertyTaxAmount, setPropertyTaxAmount] = useState(defaultPropertyTaxAmount);
  const [homeownersInsurancePercent] = useState(defaultHomeownerInsuranceRate);
  const [homeownersInsuranceAmount, setHomeownersInsuranceAmount] = useState(defaultHomeownerInsuranceAmount);

  const { formattedValues, formatValue } = useFormattedNumber();

  const [errors, setErrors] = useState({});

  // const [, setState] = useState(); //This is for re-rendering at will.

  const IS_LOAN_TYPE_INTEREST_ONLY = loanTerm.indexOf("INT. ONLY") > 0;

  const numberFormatter = new Intl.NumberFormat("en", {
    maximumFractionDigits: 2,
    minimumFractionDigits: 0,
    style: "currency",
    currency: "USD"
  });

  const validateFields = () => {
    let currentDownPaymentAmount = getSetInputValue(decimalInputRefs.current["down-payment-amount"], {});
    let currentDownPaymentPercentage = getSetInputValue(decimalInputRefs.current["down-payment-percentage"], {});
    let currentPurchasePrice = getSetInputValue(decimalInputRefs.current["purchase-price"], {});
    let currentHomeValueAmount = getSetInputValue(decimalInputRefs.current["home-value-amount"], {});
    let currentCashOutAmount = getSetInputValue(decimalInputRefs.current["cash-out-amount"], {});
    let currentLoanAmount = getSetInputValue(decimalInputRefs.current["loan-amount"], {});

    const DOWN_PAYMENT_PERCENTAGE_ERROR_MESSAGE =
      currentDownPaymentPercentage > 100 || currentDownPaymentPercentage < 0
        ? "Percentage must be between 0 and 100."
        : "";

    const DOWN_PAYMENT_AMOUNT_ERROR_MESSAGE =
      currentDownPaymentAmount > currentPurchasePrice * 0.99
        ? "Down Payment must be between $0 and " + numberFormatter.format(currentPurchasePrice * 0.99)
        : "";

    const PURCHASE_PRICE_ERROR_MESSAGE =
      currentPurchasePrice > currentHomeValueAmount * 1.1
        ? "For Existing Mortgage over Home Value contact your local loan officer for a custom quote."
        : "";

    let newErrorsObject = errors;

    const SET_ERROR_BY_LOAN_TYPE = {
      Refinance: () => {
        let cashOutAmountError =
          currentCashOutAmount > 500000
            ? "For Cash Out amount over $500,000 contact your local loan officer for a custom quote."
            : "";
        getSetInputError(decimalInputRefs.current["cash-out-amount"], cashOutAmountError);
        newErrorsObject["cash-out-amount"] = cashOutAmountError;

        getSetInputError(decimalInputRefs.current["purchase-price"], PURCHASE_PRICE_ERROR_MESSAGE);
        newErrorsObject["purchase-price"] = PURCHASE_PRICE_ERROR_MESSAGE;
      },
      HELOC: () => {
        let cashOutAmountError =
          currentCashOutAmount > 1000000 || currentPurchasePrice + currentCashOutAmount > 3000000
            ? "For equity credit line over $1,000,000 OR combined loan amount over $3,000,000 contact your local loan officer for a custom quote."
            : "";
        getSetInputError(decimalInputRefs.current["cash-out-amount"], cashOutAmountError);
        newErrorsObject["cash-out-amount"] = cashOutAmountError;

        getSetInputError(decimalInputRefs.current["purchase-price"], PURCHASE_PRICE_ERROR_MESSAGE);
        newErrorsObject["purchase-price"] = PURCHASE_PRICE_ERROR_MESSAGE;
      },
      "Lot Loan": () => {
        let loanAmountError =
          currentLoanAmount > 700000
            ? "For loan amounts greater than $700,000, contact a local loan officer for a custom quote."
            : "";
        getSetInputError(decimalInputRefs.current["loan-amount"], loanAmountError);
        newErrorsObject["loan-amount"] = loanAmountError;
      }
    };

    if (loanType !== "Lot Loan") {
      let loanAmountError =
        currentLoanAmount > 3000000
          ? "For loan amounts greater than $3,000,000, contact a local loan officer for a custom quote."
          : "";
      getSetInputError(decimalInputRefs.current["loan-amount"], loanAmountError);
      newErrorsObject["loan-amount"] = loanAmountError;
    }

    // This is executed in all loanType cases

    getSetInputError(decimalInputRefs.current["down-payment-amount"], DOWN_PAYMENT_AMOUNT_ERROR_MESSAGE);
    newErrorsObject["down-payment-amount"] = DOWN_PAYMENT_AMOUNT_ERROR_MESSAGE;

    getSetInputError(decimalInputRefs.current["down-payment-percentage"], DOWN_PAYMENT_PERCENTAGE_ERROR_MESSAGE);
    newErrorsObject["down-payment-percentage"] = DOWN_PAYMENT_PERCENTAGE_ERROR_MESSAGE;
    setErrors(newErrorsObject);

    SET_ERROR_BY_LOAN_TYPE[loanType] != null && SET_ERROR_BY_LOAN_TYPE[loanType]();
  };

  // Helper function to get the number of years from the loan term
  const getLoanTermYears = () => {
    // We split the selected Loan Term "30 Year Fixed" and check is the first position is a number
    // in this case "30", then we parse it and return the number, else we return the default 30 years number.
    let loanTermYears =
      loanTerm.split(" ").length && !isNaN(loanTerm.split(" ")[0]) ? parseInt(loanTerm.split(" ")[0]) : 30;

    // console.log("LOAN TERM YEARS:", loanTermYears, "|", loanTerm);

    return loanTermYears;
  };

  const getAdjustedLoanAmount = () => {
    const DEFAULT_ADJUSTED_LOAN_AMOUNT = 0;

    const ADJUSTED_LOAN_AMOUNT = {
      "Home Loan": purchasePrice - downPaymentAmount,
      "Lot Loan": purchasePrice - downPaymentAmount,
      HELOAN: loanAmount,
      Remodeling: loanAmount,
      Construction: loanAmount,
      Refinance: purchasePrice + cashOutAmount,
      HELOC: cashOutAmount
    };

    return ADJUSTED_LOAN_AMOUNT[loanType] || DEFAULT_ADJUSTED_LOAN_AMOUNT;
  };

  // THIS IS THE MAIN CALCULATE FUNCTION to calculate the estimated monthly payment
  const handleCalculate = () => {
    validateFields();
    if (!Object.keys(errors).some((field) => errors[field] !== "")) {
      let adjustedLoanAmount = getAdjustedLoanAmount();
      let loanTermMonths = getLoanTermYears() * 12;
      let monthlyPayment;
      if (!interestRate) {
        monthlyPayment = IS_LOAN_TYPE_INTEREST_ONLY ? 0 : adjustedLoanAmount / loanTermMonths;
      } else {
        let monthlyInterestRate = interestRate / 100 / 12;
        monthlyPayment = IS_LOAN_TYPE_INTEREST_ONLY
          ? (adjustedLoanAmount * interestRate) / 100 / 12
          : (adjustedLoanAmount * monthlyInterestRate * Math.pow(1 + monthlyInterestRate, loanTermMonths)) /
            (Math.pow(1 + monthlyInterestRate, loanTermMonths) - 1);
      }
      setEstimatedMonthlyPayment(Math.ceil(monthlyPayment));
      if (adjustedLoanAmount != null && decimalInputRefs.current["loan-amount"] != null) {
        getSetInputValue(decimalInputRefs.current["loan-amount"], {
          newValue: parseFloat(adjustedLoanAmount)
        });
      }
    }
  };

  useEffect(() => {
    let value = homeValueAmount ? homeValueAmount : purchasePrice;
    setHomeownersInsuranceAmount(parseInt((value * homeownersInsurancePercent * 0.01) / 12, 10));
    setPropertyTaxAmount(parseInt((value * propertyTaxPercent * 0.01) / 12, 10));
  }, [purchasePrice, homeValueAmount, homeownersInsurancePercent, propertyTaxPercent]);

  const [ratesData, setRatesData] = useState([]);
  const [ratesDataLoaded, setRatesDataLoaded] = useState(false);

  useEffect(() => {
    if (ratesDataLoaded) handleCalculate();
  }, [purchasePrice, loanAmount, downPaymentAmount, cashOutAmount, interestRate, loanTerm, ratesDataLoaded]); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchRatesData = () => {
    const host = window.location.hostname === "www.wafdbank.com" ? "https://wafdapi.com" : "https://test.wafdapi.com";
    axios
      .get(`${host}/mortgage-rates.json`)
      .then((response) => {
        // This is a temporary thing to duplicate the Construction Loan Type creating the Remodeling Loan Type with the same info
        let finalData = [...response.data];

        let constructionData = response.data.find((data) => data.LoanType === "Construction");

        if (constructionData) {
          constructionData = { ...constructionData, LoanType: "Remodeling" };
          finalData.push(constructionData);
        }

        // When we add the Remodeling to the API, we will use response.data directly and we can remove finalData
        setRatesData(finalData);
        // console.log("THE APRs:", finalData);
        /* console.log(
            "THE LOAN TYPEs:",
            finalData.map((rate) => rate.LoanType)
          ); */
        setRatesDataLoaded(true);
      })
      .catch(function (error) {
        console.log("error", error);
        // Default value if we don't have access to the API locally
        setRatesData(undefined);
        setRatesDataLoaded(true);
      });
  };

  useEffect(() => {
    fetchRatesData();
  }, []);

  // START Form methods
  useEffect(() => {
    // Validate if the form input refs are initialized.
    if (Object.keys(decimalInputRefs.current).length) {
      let newLoanAmount = purchasePrice - downPaymentAmount;
      getSetInputValue(decimalInputRefs.current["loan-amount"], {
        newValue: newLoanAmount
      });

      // Check the forms that uses the cash-out-amount input instead of the down-payment-amount
      if (loanType === "Refinance" || loanType === "HELOC") {
        let newLoanAmount = purchasePrice + cashOutAmount;
        getSetInputValue(decimalInputRefs.current["loan-amount"], {
          newValue: newLoanAmount
        });
      }
    }
  }, [purchasePrice, downPaymentAmount, cashOutAmount]);

  const decimalInputRefs = useRef({});

  useEffect(() => {
    // Populate the ref object with refs created for each child
    if (Object.keys(decimalInputRefs.current).length === 0) {
      Object.keys(FORM_INITIAL_VALUES.values).forEach((inputId) => {
        decimalInputRefs.current[inputId] = React.createRef();
      });
    }
  }, [FORM_INITIAL_VALUES.values]);

  // NOTE: This function is sent to memoized components, so you can't use states from this file, you have to get updated values
  // from a reliable source, each time you will use for calculations instead of just using the state
  // this applies just for the use inside this function, the states works as expected outside of the function
  const handleOnChange = (event) => {
    const { target: inputTarget } = event;
    // console.log("ON CHANGE PROPS:", inputInfo, inputValue);
    const elementType = inputTarget.tagName.toLowerCase();
    // console.log("ON CHANGE TRIGGERED");

    if (elementType === "input") {
      const inputValue = getSetInputValue(decimalInputRefs.current[inputTarget.id], {
        event,
        inputTarget,
        newValue: inputTarget.value
      });

      // Handle input element
      const ON_CHANGE_INPUTS = {
        "purchase-price": () => {
          // This gets from a reliable source the real current value of the down payment percentage input
          let currentDownPaymentPercentage = getSetInputValue(decimalInputRefs.current["down-payment-percentage"], {});
          let downPaymentAmountValue = inputValue * currentDownPaymentPercentage * 0.01;
          getSetInputValue(decimalInputRefs.current["down-payment-amount"], {
            newValue: downPaymentAmountValue
          });
        },
        "home-value-amount": () => {
          // setHomeValueAmount(inputValue);
        },
        "loan-amount": () => {
          // setLoanAmount(inputValue);
        },
        "interest-rate": () => {
          // setInterestRate(inputValue);
        },
        "down-payment-amount": () => {
          let newDownPaymentAmount = inputValue;
          setDownPaymentAmount(newDownPaymentAmount);

          let currentPurchasePrice = getSetInputValue(decimalInputRefs.current["purchase-price"], {});
          let tempDownPaymentPercent = newDownPaymentAmount ? (newDownPaymentAmount * 100) / currentPurchasePrice : 0;

          getSetInputValue(decimalInputRefs.current["down-payment-percentage"], {
            newValue: Math.ceil(tempDownPaymentPercent)
          });

          if (newDownPaymentAmount > currentPurchasePrice * 0.99) {
            let fixedValue = getSetInputValue(decimalInputRefs.current[inputTarget.id], {
              event,
              inputTarget,
              newValue: currentPurchasePrice * 0.99
            });

            setDownPaymentAmount(fixedValue);

            let tempDownPaymentPercent = fixedValue ? (fixedValue * 100) / currentPurchasePrice : 0;

            getSetInputValue(decimalInputRefs.current["down-payment-percentage"], {
              newValue: Math.ceil(tempDownPaymentPercent)
            });

            inputTarget.select();
          }
        },
        "down-payment-percentage": () => {
          let newValue = inputValue ? Math.ceil(inputValue) : 0;
          getSetInputValue(decimalInputRefs.current["down-payment-percentage"], {
            newValue: newValue
          });

          let currentPurchasePrice = getSetInputValue(decimalInputRefs.current["purchase-price"], {});

          let downPaymentAmountValue = currentPurchasePrice * newValue * 0.01;

          getSetInputValue(decimalInputRefs.current["down-payment-amount"], {
            newValue: downPaymentAmountValue
          });
        },
        "cash-out-amount": () => {
          // setCashOutAmount(inputValue);
        }
      };

      ON_CHANGE_INPUTS[inputTarget.id]();
      return;
    }

    if (elementType === "select") {
      // Handle select element
      const ON_CHANGE_SELECTS = {
        "dd-loan-term": () => {
          getSetSelectValue(decimalInputRefs.current["dd-loan-term"], inputTarget.value);
        }
      };

      ON_CHANGE_SELECTS[inputTarget.id]();
      return;
    }

    // Default behavior or handle other types of elements
    // console.log("Handling other types of elements");
  };

  const handleOnBlur = () => {
    validateFields();
  };
  // END Form methods

  return (
    <>
      <section id="mortgage-calculator-section" className={"container-md " + styles.calculatorSection}>
        <div className="bg-gray-5 calculator-outline calculator-box-shadow">
          <div className="container container-fluid-md px-0">
            <div className="py-4 px-3 px-md-4">
              <MortgageCalculatorTitle isTitleH1={propIsTitleH1} title={propTitle} isSpanish={isSpanish} />
              <div className="row">
                {ratesDataLoaded && (
                  <>
                    <CalculatorForm
                      ratesData={ratesData}
                      loanType={loanType}
                      isSpanish={isSpanish}
                      FORM_INITIAL_VALUES={FORM_INITIAL_VALUES.values}
                      FORM_SET_STATES={FORM_INITIAL_VALUES.setStates}
                      loanTerm={loanTerm}
                      setLoanTerm={setLoanTerm}
                      decimalInputRefs={decimalInputRefs}
                      formFunctions={{
                        handleOnChange,
                        handleOnBlur
                      }}
                    />

                    <APRRateInfo
                      ratesData={ratesData}
                      calculatorTitle={propTitle}
                      loanType={loanType}
                      isSpanish={isSpanish}
                    />

                    <MonthlyPaymentInfo
                      ratesData={ratesData}
                      showTaxesAndInsurance={showTaxesAndInsurance}
                      estimatedMonthlyPaymentFormatted={numberFormatter.format(estimatedMonthlyPayment)}
                      estimatedMonthlyPaymentAmount={estimatedMonthlyPayment || 1234.56}
                      propertyTaxAmount={propertyTaxAmount}
                      homeownersInsuranceAmount={homeownersInsuranceAmount}
                      loanType={loanType}
                      IS_LOAN_TYPE_INTEREST_ONLY={IS_LOAN_TYPE_INTEREST_ONLY}
                      isSpanish={isSpanish}
                    />

                    <OtherLoanCalculatorsLinks ratesData={ratesData} loanType={loanType} isSpanish={isSpanish} />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      {ratesDataLoaded && (
        <MortgageCalculatorDisclaimers
          ratesData={ratesData}
          loanType={loanType}
          isSpanish={isSpanish}
          IS_LOAN_TYPE_INTEREST_ONLY={IS_LOAN_TYPE_INTEREST_ONLY}
          sectionClass={disclaimersSectionClass}
        />
      )}
    </>
  );
};

export default NewMortgageCalculator;

NewMortgageCalculator.propTypes = {
  isTitleH1: PropTypes.bool,
  title: PropTypes.string.isRequired,
  // NOTE: This array of Loan Types must be updated when we update the Loan Types in the API
  loanType: PropTypes.oneOf([
    "Home Loan",
    "Refinance",
    "Construction",
    "Lot Loan",
    "HELOC",
    "Smart Start",
    "HELOAN",
    "Remodeling"
  ]).isRequired,
  loanTerm: PropTypes.string.isRequired,
  homeValue: PropTypes.number,
  interestRate: PropTypes.number,
  estimatedPayment: PropTypes.number,
  purchasePrice: PropTypes.number,
  cashOutAmount: PropTypes.number,
  downPaymentPercent: PropTypes.number,
  downPaymentAmount: PropTypes.number,
  loanAmount: PropTypes.number,
  isSpanish: PropTypes.bool,
  disclaimersSectionClass: PropTypes.string,
  showTaxesAndInsurance: PropTypes.bool
};

