import React, { useEffect, useState } from "react";

const MemoizedSelectInput = ({ labelText, selectRef, setLoanTerm, selectProps }) => {
  const { errors, errorsLTV, initialValue, ...remainingProps } = selectProps;

  const [loanTermSelected, setLoanTermSelected] = useState("");

  const getSetSelectValue = (value) => {
    console.log("HICE ALGO EN EL SELECT")
    setLoanTerm(value);
    setLoanTermSelected(value);
    return value;
  };

  const ComponentContainer = ({ id, labelText, errors, errorsLTV, component }) => {
    return (
      <div id={"select-container-" + id} className="col-md-6" style={{ zIndex: 2 }}>
        <div className="form-group">
          <label htmlFor={id} className="text-black">
            {labelText}
          </label>
          {component}
        </div>
        {errorsLTV && <span className="form-text text-danger mt-n3 mb-3">{errorsLTV}</span>}
        {errors && <span className="form-text text-danger mt-n3 mb-3">{errors}</span>}
      </div>
    );
  };

  useEffect(() => {
    setLoanTerm(initialValue);
    setLoanTermSelected(initialValue);
  }, []);

  // Assigning the ref to the decimalInputRef prop
  React.useImperativeHandle(selectRef, () => ({
    getSetSelectValue
  }));

  return (
    <ComponentContainer
      id={selectProps.id}
      labelText={labelText}
      errors={errors}
      errorsLTV={errorsLTV}
      component={
        <select
          ref={selectRef}
          className="form-control text-black custom-select-chevron border-radius-6"
          value={loanTermSelected}
          {...remainingProps}
        >
          {selectProps.options.map((term, index) => (
            <option key={`${term} ${index}`} value={term}>
              {term}
            </option>
          ))}
        </select>
      }
    />
  );
};

export default React.memo(MemoizedSelectInput, () => true);
