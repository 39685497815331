import React from "react";
import PropTypes from "prop-types";

/**
 * This is the component to render the pie chart and the amount of the estimated monthly payment.
 *
 * @param {Object} props - The object containing props.
 * @param {string} props.label - Descriptive text for the amount.
 * @param {string} props.amount - Currency amount as string.
 * @param {boolean} props.textSmall - Whether to show the small version or not.
 * @param {number} props.maxWidth - Max Width of the container for the label and amount.
 * @param {number} props.maxHeight - Max Height of the container for the label and amount.
 * @returns {React.JSX.Element} Returns the JSX component to render.
 */
const PieChartLabel = ({
  label = "Estimated Monthly Payment",
  amount = "$1,000",
  textSmall = false,
  maxWidth = 228,
  maxHeight = 228
}) => {
  return (
    <div
      className="row mx-0 ml-sm-3 ml-md-0 ml-lg-3 align-items-center justify-content-center"
      style={{
        position: "absolute",
        inset: 0,
        maxWidth,
        maxHeight
      }}
    >
      {/* Your content for the center */}
      {textSmall ? (
        <div className="text-white d-flex align-items-center justify-content-center" style={{ width: 105, height: 90 }}>
          <div>
            <p className="text-xs text-center mb-1">{label}</p>
            <h4 id="estimated-monthly-payment" className="font-weight-bold text-center mb-0">
              {amount}
            </h4>
          </div>
        </div>
      ) : (
        <div className="text-white d-flex align-items-center justify-content-center" style={{ width: 150, height: 90 }}>
          <div>
            <h5 className="font-weight-normal text-center mb-1">{label}</h5>
            <h3 id="estimated-monthly-payment" className="font-weight-bold text-center mb-0">
              {amount}
            </h3>
          </div>
        </div>
      )}
    </div>
  );
};

export default PieChartLabel;

PieChartLabel.propTypes = {
  label: PropTypes.string.isRequired,
  amount: PropTypes.string.isRequired,
  textSmall: PropTypes.bool,
  maxWidth: PropTypes.number.isRequired,
  maxHeight: PropTypes.number.isRequired
};
